import film from '../assets/svgs/film.svg';
import certificate from '../assets/svgs/file-certificate.svg';
import download from '../assets/svgs/arrow-down-to-line.svg';
import styles from './Download.module.css'
import { useState } from 'react';

function Download(props) {
	const { videoPrizeSrc, scholarshipSrc, name } = props
	const [isVideoBtnHovered, setIsVideoBtnHovered] = useState(false)
	const [isScholarshipBtnHovered, setIsScholarshipBtnHovered] = useState(false)

	const handleMouseEnter = (e) => {
		switch (e.target.dataset.btn) {
			case 'video':
				setIsVideoBtnHovered(true)
				break;

			case 'scholarship':
				setIsScholarshipBtnHovered(true)
				break;

			default:
				break;
		}
	}

	const handleMouseLeave = (e) => {
		switch (e.target.dataset.btn) {
			case 'video':
				setIsVideoBtnHovered(false)
				break;

			case 'scholarship':
				setIsScholarshipBtnHovered(false)
				break;

			default:
				break;
		}
	}

	return (
		<div className={styles.download}>
			{
				!name &&
				<a
					data-btn='video'
					className={`${styles.downloadBtn} ${styles.videoPrizeBtn}`}
					href={videoPrizeSrc}
					target='_blank'
					rel='noreferrer'
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				>
					<img src={isVideoBtnHovered ? download : film} alt='Video Prize Download Button' />
				</a>
			}
			<a
				data-btn='scholarship'
				className={`${styles.downloadBtn} ${styles.scholarshipBtn}`}
				href={scholarshipSrc}
				target='_blank'
				rel='noreferrer'
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<img src={isScholarshipBtnHovered ? download : certificate} alt='Scholarship Download Button' />
			</a>
		</div>
	)
}

export default Download