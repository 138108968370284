import Select from 'react-select'

function CitySelect(props) {
	const { selectContainer, selectControl, name, required, setInfo } = props
	const options = [
		{ value: 'An Giang', label: 'An Giang' },
		{ value: 'Bà Rịa - Vũng Tàu', label: 'Bà Rịa - Vũng Tàu' },
		{ value: 'Bạc Liêu', label: 'Bạc Liêu' },
		{ value: 'Bắc Giang', label: 'Bắc Giang' },
		{ value: 'Bắc Kạn', label: 'Bắc Kạn' },
		{ value: 'Bắc Ninh', label: 'Bắc Ninh' },
		{ value: 'Bến Tre', label: 'Bến Tre' },
		{ value: 'Bình Dương', label: 'Bình Dương' },
		{ value: 'Bình Định', label: 'Bình Định' },
		{ value: 'Bình Phước', label: 'Bình Phước' },
		{ value: 'Bình Thuận', label: 'Bình Thuận' },
		{ value: 'Cà Mau', label: 'Cà Mau' },
		{ value: 'Cao Bằng', label: 'Cao Bằng' },
		{ value: 'Cần Thơ', label: 'Cần Thơ' },
		{ value: 'Đà Nẵng', label: 'Đà Nẵng' },
		{ value: 'Đắk Lắk', label: 'Đắk Lắk' },
		{ value: 'Đắk Nông', label: 'Đắk Nông' },
		{ value: 'Điện Biên', label: 'Điện Biên' },
		{ value: 'Đồng Nai', label: 'Đồng Nai' },
		{ value: 'Đồng Tháp', label: 'Đồng Tháp' },
		{ value: 'Gia Lai', label: 'Gia Lai' },
		{ value: 'Hà Giang', label: 'Hà Giang' },
		{ value: 'Hà Nam', label: 'Hà Nam' },
		{ value: 'Hà Nội', label: 'Hà Nội' },
		{ value: 'Hà Tĩnh', label: 'Hà Tĩnh' },
		{ value: 'Hải Dương', label: 'Hải Dương' },
		{ value: 'Hải Phòng', label: 'Hải Phòng' },
		{ value: 'Hậu Giang', label: 'Hậu Giang' },
		{ value: 'Hòa Bình', label: 'Hòa Bình' },
		{ value: 'Hưng Yên', label: 'Hưng Yên' },
		{ value: 'Khánh Hòa', label: 'Khánh Hòa' },
		{ value: 'Kiên Giang', label: 'Kiên Giang' },
		{ value: 'Kon Tum', label: 'Kon Tum' },
		{ value: 'Lai Châu', label: 'Lai Châu' },
		{ value: 'Lạng Sơn', label: 'Lạng Sơn' },
		{ value: 'Lào Cai', label: 'Lào Cai' },
		{ value: 'Lâm Đồng', label: 'Lâm Đồng' },
		{ value: 'Long An', label: 'Long An' },
		{ value: 'Nam Định', label: 'Nam Định' },
		{ value: 'Nghệ An', label: 'Nghệ An' },
		{ value: 'Ninh Bình', label: 'Ninh Bình' },
		{ value: 'Ninh Thuận', label: 'Ninh Thuận' },
		{ value: 'Phú Thọ', label: 'Phú Thọ' },
		{ value: 'Phú Yên', label: 'Phú Yên' },
		{ value: 'Quảng Bình', label: 'Quảng Bình' },
		{ value: 'Quảng Nam', label: 'Quảng Nam' },
		{ value: 'Quảng Ngãi', label: 'Quảng Ngãi' },
		{ value: 'Quảng Ninh', label: 'Quảng Ninh' },
		{ value: 'Quảng Trị', label: 'Quảng Trị' },
		{ value: 'Sóc Trăng', label: 'Sóc Trăng' },
		{ value: 'Sơn La', label: 'Sơn La' },
		{ value: 'Tây Ninh', label: 'Tây Ninh' },
		{ value: 'Thái Bình', label: 'Thái Bình' },
		{ value: 'Thái Nguyên', label: 'Thái Nguyên' },
		{ value: 'Thanh Hóa', label: 'Thanh Hóa' },
		{ value: 'Thừa Thiên Huế', label: 'Thừa Thiên Huế' },
		{ value: 'Tiền Giang', label: 'Tiền Giang' },
		{ value: 'TP. Hồ Chí Minh', label: 'TP. Hồ Chí Minh' },
		{ value: 'Trà Vinh', label: 'Trà Vinh' },
		{ value: 'Tuyên Quang', label: 'Tuyên Quang' },
		{ value: 'Vĩnh Long', label: 'Vĩnh Long' },
		{ value: 'Vĩnh Phúc', label: 'Vĩnh Phúc' },
		{ value: 'Yên Bái', label: 'Yên Bái' }
	]

	const handleSelectChange = (e) => {
		setInfo(prevState => ({
			...prevState,
			[name]: e?.value ?? ''
		}))
	}

	return (
		<Select
			classNames={{
				container: () => selectContainer,
				control: () => selectControl
			}}
			placeholder=''
			required={required}
			options={options}
			isClearable={true}
			onChange={handleSelectChange}
		></Select>
	)
}

export default CitySelect